import axios from 'axios'
import VueAxios from 'vue-axios'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.min.css'
import './assets/css/style.css'
import Vue from "vue"
import PortalVue from 'portal-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { localize } from 'vee-validate'
import fr from 'vee-validate/dist/locale/fr.json'
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./router"
import store from "./store"
import { initAxios } from '@/api/api'
import '../public/assets/css/style.css'
import './directives'
import './filters'
import { abilitiesPlugin } from '@casl/vue'
import ability from './acsl/define-ability'
import { initializeFormValidation } from './utils/form-validation-rules'
import VueEllipseProgress from 'vue-ellipse-progress'
import Vue2Filters from 'vue2-filters'
import { VueEditor } from 'vue2-editor'
import VueToast from 'vue-toast-notification'
// import VueConfirmDialog from 'vue-confirm-dialog'
import 'vue-toast-notification/dist/theme-sugar.css'
import Dialog from 'bootstrap-vue-dialog'
import CSelect from './components/CSelect.vue'
import CIdentifiantInput from './components/common/CIdentifiantInput.vue'
import JsonExcel from "vue-json-excel"
import FileUpload from 'primevue/fileupload';
import VueLoading from 'vuejs-loading-plugin';
import VueSignaturePad from 'vue-signature-pad'; 
import VueHtml2pdf from 'vue-html2pdf'
import VueBootstrapTypeahead from './components/third-party/VueBootstrapTypeahead.vue'     
import PrimeVue from 'primevue/config'
import Ripple from 'primevue/ripple'    
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'
import 'primevue/resources/primevue.css'
import 'primeicons/primeicons.css'
import {XDialog} from './plugin/dialog/plugin.js'

Vue.use(abilitiesPlugin, ability, {
  useGlobalProperties: true
})
Vue.use(Dialog, {})
Vue.component("downloadExcel", JsonExcel)
Vue.component('c-select', CSelect)
Vue.component('c-indentifiant-input', CIdentifiantInput)
Vue.use(VueHtml2pdf)
Vue.use(VueSignaturePad);
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)
Vue.use(VueToast)

initAxios() // Initialize axios

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(VueAxios, axios)
Vue.use(PortalVue)
Vue.use(Vue2Filters)
Vue.use(VueEditor)
Vue.use(VueLoading)
Vue.use(VueEllipseProgress)

import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing)

import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import 'dayjs/locale/fr'; 

dayjs.locale('fr');
dayjs.extend(relativeTime);

// Make Day.js available globally as $dayjs(
Vue.prototype.$dayjs = dayjs;

Vue.use(PrimeVue, {
  ripple: true,
  locale: {
      closeText: 'Fermer',
      prevText: 'Précédent',
      nextText: 'Suivant',
      monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre' ],
      monthNamesShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc' ],
      dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
      weekHeader: 'Semaine',
      firstDay: 1,
      dateFormat: 'mm/dd/yy',
      isRTL: false,
      showMonthAfterYear: false,
      yearSuffix:'',
      timeOnlyTitle: 'Choisir l\'heure',
      timeText: 'Heure',
      hourText: 'Heures',
      minuteText: 'Minutes',
      secondText: 'Secondes',
      currentText: 'Maintenant',
      ampm: false,
      month: 'Mois',
      week: 'Semaine',
      day: 'Jour',
      allDayText: 'Toute la journée',
      accept: 'Oui',
      reject: 'Non',
  }
})
Vue.directive('ripple', Ripple)


import ConfirmationService from 'primevue/confirmationservice'
Vue.use(ConfirmationService)

import VueScreen from 'vue-screen';
Vue.use(VueScreen, {
  sm: '0px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px',  
}); 

import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import OverlayPanel from 'primevue/overlaypanel'
// import Calendar from 'primevue/calendar'
import Card from 'primevue/card'
import Textarea from 'primevue/textarea'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import AutoComplete from 'primevue/autocomplete'
import MultiSelect from 'primevue/multiselect'
import Dropdown from 'primevue/dropdown'
import Divider from 'primevue/divider'
import Button from 'primevue/button'
import ProgressBar from 'primevue/progressbar'
import ProgressSpinner from 'primevue/progressspinner';
import TieredMenu from 'primevue/tieredmenu'
import ToggleButton from 'primevue/togglebutton'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import SplitButton from 'primevue/splitbutton'
import Chart from 'primevue/chart'
import Skeleton from 'primevue/skeleton'
import DataTable from 'primevue/datatable'
import Row from 'primevue/row'
import Column from 'primevue/column'
import ColumnGroup from 'primevue/columngroup'
import Menu from 'primevue/menu';
import PickList from 'primevue/picklist';
import ListBox from 'primevue/listbox';
import SelectButton from 'primevue/selectbutton';
import Steps from 'primevue/steps';
import PDialog from 'primevue/dialog';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import Checkbox from 'primevue/checkbox';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmDialog from 'primevue/confirmdialog';
import Editor from 'primevue/editor';
import CascadeSelect from 'primevue/cascadeselect'
import Tag from 'primevue/tag'
import Badge from 'primevue/badge'
import Message from 'primevue/message'
import InputSwitch from 'primevue/inputswitch'
import Menubar from 'primevue/menubar';
import RadioButton from 'primevue/radiobutton'
import Avatar from 'primevue/avatar'
import TabMenu from "primevue/tabmenu";
import Sidebar from 'primevue/sidebar';
import Chip from 'primevue/chip';
// import Rating from 'primevue/rating';
import Rating from './components/common/CRating';
import Tooltip from 'primevue/tooltip';
import BadgeDirective from 'primevue/badgedirective';
import ImagePreview from 'primevue/imagepreview';
import Galleria from 'primevue/galleria';
import Knob from 'primevue/knob';

import CCalendar from './components/common/CCalendar'

Vue.directive('badge', BadgeDirective);
Vue.directive('tooltip', Tooltip)


// import Select from 'primevue/multiselect';
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";


Vue.use(VueSweetalert2);

// Vue.use(VueConfirmDialog)

// Vue.component('vue-confirm-dialog', VueConfirmDialog.default)


localize({
  fr,
})

Vue.component('PCalendar', CCalendar)

Vue.component('PTabView', TabView)
Vue.component('PTabPanel', TabPanel)
Vue.component('POverlayPanel', OverlayPanel)
Vue.component('PCard', Card)
Vue.component("PTabMenu", TabMenu);
Vue.component('PInputText', InputText)
Vue.component('PTextarea', Textarea)
Vue.component('PInputNumber', InputNumber)
Vue.component('PAutoComplete', AutoComplete)
Vue.component('PDropdown', Dropdown)
Vue.component('PMultiSelect', MultiSelect)
Vue.component('PDivider', Divider)
Vue.component('PButton', Button)
Vue.component('PProgressBar', ProgressBar)
Vue.component('PProgressSpinner', ProgressSpinner)
Vue.component('PTieredMenu', TieredMenu)
Vue.component('PToggleButton', ToggleButton)
Vue.component('PChart', Chart)
Vue.component('PAccordion', Accordion)
Vue.component('PAccordionTab', AccordionTab)
Vue.component('PSplitButton', SplitButton)
Vue.component('PSkeleton', Skeleton)
Vue.component('PDataTable', DataTable)
Vue.component('PColumn', Column)
Vue.component('PRow', Row)
Vue.component('PColumnGroup', ColumnGroup)
Vue.component('PMenu', Menu)
Vue.component('PMenubar', Menubar)
Vue.component('PPickList', PickList)
Vue.component('PListBox', ListBox)
Vue.component('PDialog', PDialog)
Vue.component('PSelectButton', SelectButton)
Vue.component('PSteps', Steps)
// Vue.component('PConfirmPopup', ConfirmPopup)
// Vue.component('PConfirmDialog', ConfirmDialog)
Vue.component('PTriStateCheckbox', TriStateCheckbox)
Vue.component('PCheckbox', Checkbox)
Vue.component('PRadioButton', RadioButton)
Vue.component('PEditor', Editor)
Vue.component('PCascadeSelect', CascadeSelect)
Vue.component('PTag', Tag)
Vue.component('PBadge', Badge)
Vue.component('PMessage', Message)
Vue.component('PInputSwitch', InputSwitch)
Vue.component('PAvatar', Avatar)
Vue.component('PSidebar', Sidebar)
Vue.component('PChip', Chip)
Vue.component('PRating', Rating)
Vue.component('PKnob', Knob)
Vue.component('PImagePreview', ImagePreview)
Vue.component('PGalleria', Galleria)


Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

Vue.component('FileUpload', FileUpload);

Vue.use(XDialog)

initializeFormValidation() //Initialize form validation

new Vue({
  router,
  store,


  render: (h) => h(App),
  mounted() {
    // AOS.init()
    // this.$nextTick(() => {
    //   const $ = window.$
    //   console.log($)

    //   $('#slide_actualite').slick({
    //     speed: 300,
    //     autoplay: true,
    //     autoplaySpeed: 5000,
    //     infinite: false,
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     useTransform:false,
    //     responsive: [{
    //     // breakpoint: 767.98,
    //     // settings: {
    //     //   slidesToShow: 2,
    //     //   slidesToScroll: 1,
    //     // },
    //     breakpoint: 575.98,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     }
    //     }],
    //     appendArrows: $('#button_slide_actualite'),
    //     prevArrow: '<button type="button" class="slick-prev btn btn-primary"><i class="bi bi-arrow-left"></i></button>',
    //     nextArrow: '<button type="button" class="slick-next btn btn-primary"><i class="bi bi-arrow-right"></i></button>'
    // })
    // $('#slide_actualite .bg-white').height($('#slide_actualite').height())
    // })
  },
}).$mount("#app")
