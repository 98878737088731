import { post, put, get, downloadFileWithAxiosPost, downloadFile} from '@/api/api'
import { buildQueryParams } from '../../utils/pagination-util'
import { API_URL } from '../../config/api-config'
export const actions = {
  fetchLiquidations({ commit, state }, params = {}){
    let queryParams = buildQueryParams(params, state.pagination)
    return get(`/liquidations?${queryParams}`)
    .then(({ data }) =>{
      commit('addLiquidations', data)
      return data
    })
  },
  fetchLiquidation({ commit, state }, params = {}){
    let { liquidationId } = params
    return get(`/liquidations/${liquidationId}`)
    .then(({ data }) =>{
      commit('setLiquidation', data)
      return data
    })
  },
  telechargerLiquidationToExcel({}, params = {}){
    const { liquidationId, extraQueryArg = ''} = params
    return downloadFile(`${API_URL}/payementLiquidations/${liquidationId}/exportLiquidationToPdf?${extraQueryArg}`, 'file.xlsx')
    .then(({ data }) =>{
      return data
    })
  },
  telechargerPayementLiquidationViaOperatorToExcel({}, params = {}){
    const { liquidationId, extraQueryArg = ''} = params
    return downloadFile(`${API_URL}/payementLiquidations/${liquidationId}/exportLiquidationToOperateurExcel?${extraQueryArg}`, 'liste_paiement.csv')
    .then(({ data }) =>{
      return data
    })
  },
  exportToPdf({ commit }, params = {  }){
    let { liquidationId } = params
    return downloadFileWithAxiosPost(`/liquidations/exportToPdf`, 'file.pdf', {
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre absences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
    })
    .then(({ data }) =>{
      commit('setLiquidation', data)
      return data
    })
  },
  exportToExcel({ commit }, params = {  }){
    let { liquidationId } = params
    return downloadFileWithAxiosPost(`/liquidations/exportToExcel`, 'file.xlsx', {
      headers: {
        'Nom Prénom': 'beneficiaireNom',
        'Prénom': 'beneficiairePrenom',
        'Poste':'participationPlacement.posteOccupe', 
        'Type Emploi': 'participationPlacement.typePlacement.libelle',
        'Nbre Jours Ouvrés': 'nombreDeJourDeTravailDepuisDemarrage',
        'Nbre Qbsences': 'nombreAbsenceSansMotif',
        'Date Démarrage':'dateDemarrage', 
      },
    })
    .then(({ data }) =>{
      commit('setLiquidation', data)
      return data
    })
  },
  createLiquidation({ commit }, liquidation){
    return post('/liquidations', liquidation)
    .then(({ data }) =>{
      commit('addLiquidation', data)
      return data
    })
  },
  updateLiquidation({ commit }, liquidation){
    return put(`/liquidations/${liquidation.id}`, liquidation)
    .then(({ data }) =>{
      commit('replaceLiquidation', data)
      commit('setLiquidation', data)

      return data
    })
  },
  genererPayementLiquidation({ commit }, generatorInfo){
    const { liquidation } = generatorInfo
    return put(`/liquidations/${liquidation.id}/genererPayementLiquidations`, generatorInfo)
    .then(({ data }) => {
      // commit('replaceLiquidation', data)
      // commit('setLiquidation', data)
      return data
    })
  },
  genererPayementParEntreprise({ commit }, generatorInfo){
    const { liquidation } = generatorInfo
    return post(`/liquidations/genererPayementParEntreprise`, generatorInfo)
    .then(({ data }) => {
      return data
    })
  },
  genererMissionPayementLiquidations({ commit }, generatorInfo){
    const { liquidation } = generatorInfo
    return put(`/liquidations/${liquidation.id}/genererPayementLiquidations`, generatorInfo)
    .then(({ data }) => {
      return data
    })
  },
  genererPrestationPayementLiquidations({ commit }, generatorInfo){
    const { liquidation } = generatorInfo
    return put(`/liquidations/${liquidation.id}/genererPrestationPayementLiquidations`, generatorInfo)
    .then(({ data }) => {
      return data
    })
  },
  payerPayementLiquidations({ commit }, generatorInfo){
    const { liquidation } = generatorInfo
    return put(`/liquidations/${liquidation.id}/payerPayementLiquidations`, generatorInfo)
    .then(({ data }) => {
      return data
    })
  },
  payerPayementLiquidationPourTelehone({ commit }, generatorInfo){
    const { liquidation, phone } = generatorInfo
    return put(`/liquidations/${liquidation.id}/payerPayementLiquidations/${phone}`, generatorInfo)
    .then(({ data }) => {
      return data
    })
  },
  createOrUpdateLiquidation({ dispatch }, liquidation){
    if(liquidation.id !== undefined){
      return dispatch('updateLiquidation', liquidation)
    }
    else {
      return dispatch('createLiquidation', liquidation)
    }
  },
  selectLiquidation({ commit }, liquidation){
    commit('setLiquidation', liquidation)
  }
}