// import axios from 'axios'
import { post, patch, put } from '@/api/api'
import ShortUniqueId from 'short-unique-id';
const uid = new ShortUniqueId({ length: 10 });
import dayjs from 'dayjs';

export const actions = {
  registerDemandeur({ commit }, demandeur){
    return new Promise((resolve, reject) => {
      let payload = {
        ...demandeur,
        paysDeResidence: null,
        departement:  demandeur.departement instanceof Number?  { id: demandeur.departement } : demandeur.departement,
        commune: demandeur.commune instanceof Number? { id: demandeur.commune } : demandeur.commune,
        arrondissement: demandeur.arrondissement instanceof Number? { id: demandeur.arrondissement } : demandeur.arrondissement,
        ville: demandeur.ville instanceof Number? { id: demandeur.ville } : demandeur.ville,
        dateNais: dayjs(demandeur.dateNais, "DD-MM-YYYY").toDate().getTime(),
        niveauInstruction: demandeur.niveauInstruction != null? { id: parseInt(demandeur.niveauInstruction) } : null
      }

      let estPeuOuPasInstruit = demandeur.estInstruit != null && ( !demandeur.estInstruit  || (demandeur.estInstruit && demandeur.niveauInstruction != null && demandeur.niveauInstruction <= 10))
      
      if(demandeur.typeActivation == 'CAS_SPECIAL' || estPeuOuPasInstruit){
        const password = uid()
        payload = {
          ...payload,
          password: password,
          password1: password,
          email: payload.email != null? payload.email : 'cas.special.' + uid().toLowerCase() + '@anpe.ga'
        }
      }

      if(estPeuOuPasInstruit && demandeur.metierQualification != null){
        const  {
          metierFormation,
          metierQualification,
          metierFormationDescription,
          metierQualificationDescription
        } = payload

        payload = {
          ...payload,
          azoli: {
            metierFormation,
            metierQualification,
            metierFormationDescription,
            metierQualificationDescription
          }
        }
      }

      post(`/oauth/register/${estPeuOuPasInstruit? 'demandeur' : 'demandeur'}`, payload)
      .then(({ data }) =>{
        commit('addDemandeur', data)
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  registerEmployeur({ commit },entreprise){
    console.log(entreprise)
    return new Promise((resolve, reject) => {
      return post('/oauth/register/employeur', entreprise)
      .then(({ data }) =>{
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  // eslint-disable-next-line no-unused-vars
  confirmerInscription({ commit }, { token, username, password }){
    return new Promise((resolve, reject) => {
      return patch('/oauth/register/enable?token=' + token, {
        email: username,
        password,
        token
      })
      .then(({ data }) =>{
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  // eslint-disable-next-line no-unused-vars
  checkConfirmationToken({ commit }, { token }){
    return new Promise((resolve, reject) => {
      return patch('/oauth/register/checkToken?token=' + token)
      .then(({ data }) =>{
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  // eslint-disable-next-line no-unused-vars
  checkAvailablitity({ commit }, { type, value }){
    if(type == null) throw Error(`'type' non specifié store.account.checkAvailablitity`)
    if(value == null) throw Error(`'value' non specifié store.account.checkAvailablitity`)
    return post('/oauth/verifier/disponibiliteIdentifiant/', {
      type,
      value
    } )
    .then(({ data }) =>{
      return data
    }).catch((err) => {
      return err
    })
  },
  // eslint-disable-next-line no-unused-vars
  resetPassword({ commit }, { username }){
    return new Promise((resolve, reject) => {
      return put('/oauth/profile/resetPassword', { username })
      .then(({ data }) =>{
        resolve(data)
      }).catch((err) => {
        reject(err)
      })
    })
  },
}