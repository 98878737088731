import Vue from 'vue'
import Vue2Filters from 'vue2-filters'
import dayjs from 'dayjs';

Vue.use(Vue2Filters)

Vue.filter('age', (value) => {
    return dayjs().diff(dayjs(value), 'years');
  });
  
  Vue.filter('formatDate', (value) => {
    return dayjs(value).format('DD-MM-YYYY');
  });


  Vue.filter("dayjs", function (value, format = "DD-MM-YYYY") {
    if (!value) return ""; // Handle empty values
    return dayjs(value).format(format);
  });